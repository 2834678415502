.tableContainer{
    margin-top: 3vh;
}

.tableReport{
    text-align: center;
}

.dropdownContainer{
    margin-bottom: 2vh;
}