.testContainer{
    min-height: 100vh;
    width: 100vw;
    background: linear-gradient(to bottom, #260d42, #85285f);
    color: white;
    padding: 5vh 10vw;
}

.testHeader{
    font-size: 1.4rem;
    font-weight: bolder;
    text-align: center;
}

.miniHeader{
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
}