.footer {
  background-color: rgb(49, 14, 82);
  width: 100%;
  height: 4rem;
  display: flex;
  bottom: 0;
  justify-content: space-around;
  position: fixed;
  align-items: center;
  z-index: 20;
}

.navItem {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLink {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navItem:hover {
  background-color: rgb(76, 49, 101);
  border-radius: 10%;
}

.navLink:active {
  color: black;
}

.navLinkActive {
  color: aqua;
}

.icon {
  padding-left: 1rem;
  width: 2.3rem;
  height: 2.3rem;
}
