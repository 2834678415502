.content {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/fundal-ttjapp\ final.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10rem;
  align-items: center;
}

.logo {
  background-image: url("../../assets/images/Siglă-mov-box-fundal-galben\ \(1\).png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  width: 400px;
  height: 400px;
  background-position: center center;
  
}

@media only screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
    gap: 1rem;
  }

  .logo {
    width: 300px;
    height: 300px;
  }
}
