.header{
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}

.questionContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.question{
    min-width: 250px;
    width: 30vw;
    resize: none;
    margin-top: 8vh;
}

.error{
    font-size: 14px;
    text-align: center;
    color: red;
    margin: 0;
    height: 20px;
}

.buttonContainer{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
}

.buttonHide{
    visibility: hidden;
}