body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-password-input {
  width: 100%;
}

.p-button[type="submit"] {
  margin-top: 1rem;
  padding: 1.5rem 3rem;
  background: #8b5cf6;
  border: none;
}

.p-button[type="submit"]:hover {
  background: #6a42c7 !important;
}
