.navContainer {
  /* position: fixed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #260d42;
  padding: 1.2rem;
}

.logo {
  width: auto;
  height: 2.5rem;
}

.website {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25rem;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.onHover {
  cursor: pointer;
}
