.container {
  background-image: linear-gradient(to bottom, #280f43, #63386b);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4vh 6vw;
}

.registerCard {
  max-width: 550px;
  width: 550px;
  padding: 3rem;
  background-color: rgb(255, 255, 255);
  border-radius: 1rem;
}

.registerForm {
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  margin-bottom: 1.3rem;
  text-align: center;
}

.important{
  color: red;
  text-align: center;
}

.fieldContainer {
  margin-bottom: 0.8rem;
}

.input {
  width: 100%;
}

.containerCV {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
}

.buttonCV {
  color: white;
  font-weight: bold;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
}

.buttonCVUploaded {
  background-color: rgb(4, 77, 4);
}

.buttonCVUploaded:hover {
  cursor: pointer;
  background-color: rgb(8, 121, 8);
}

.buttonCVNotUploaded {
  background-color: rgb(39, 39, 205);
}

.buttonCVNotUploaded:hover {
  cursor: pointer;
  background-color: rgb(58, 58, 177);
}

.fileText {
  font-weight: bold;
  font-size: 1.2rem;
}

.file {
  font-weight: bolder;
  font-size: 1rem;
  color: rgb(65, 64, 64);
  display: flex;
  flex-wrap: wrap;
}

.inputCV {
  display: none;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.checkboxText {
  font-size: 0.9rem;
  padding-left: 2rem;
}

.checkboxError {
  visibility: hidden;
  color: red;
  font-weight: bolder;
  padding: 0 5%;
  text-align: center;
}

.checkboxErrorActive {
  visibility: visible;
}

.buttonContainer {
  text-align: center;
}

.buttonSubmitRegister {
  margin-top: 2rem;
  background-color: #8b5cf6;
}

@media (max-width: 500px) {
  .registerCard {
    width: 95vw;
    padding: 1.5rem;
  }

  .fieldContainer {
    padding: 0;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .registerCard {
    width: 70vw;
  }
}
