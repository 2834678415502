.menuContainer{
    position: fixed;
    min-width: 200px;
    background-color: lightgray;
    left: -100%;
    transition: 1s all;
    z-index: 10;
    bottom: 0;
    top: 3.9rem;
}

.showMenu{
    left: 0;
}

.firstSectionContainer{
    padding: 2vh 4vw;
}

.sectionContainer{
    border-top: 2px solid white;
    padding: 2vh 4vw;
}

.inlineItem{
    display: flex;
    align-items: center;
    gap: 2vw;
    width: fit-content;
}

.inlineItem:hover{
    cursor: pointer;
}

.sectionTitle{
    color: gray;
    font-size: 18px;
    font-weight: 600;
}