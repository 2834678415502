.profilePicture {
  border-radius: 100%;
  height: 5rem;
  width: 5rem;
}

.content {
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2.8rem;
}

.buttonZone {
  margin: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

input[type='file'] {
  display: none;
}

.btnCv {
  width: 20vh;
  height: 9vh;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 2vh;
  color: white;
  text-decoration: none;
}

.textButtons {
  padding-left: 4px;
}

.confirmCVMessage{
  font-size: 0.8;
  margin: 0.5rem;
}

.confirmCVChanged{
  visibility: hidden;
}

.confirmCVActive{
  visibility: visible;
}

.important{
  text-align: center;
  color: white;
}
