.container{
    color: white;
    background: linear-gradient(to bottom, #260d42, #85285f);
    min-height: 100vh;
    width: 100vw;
    padding: 1.5vh 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header{
    font-size: 1.3rem;
    font-weight: bolder;
    text-align: center;
}

.content{
    text-align: center;
}

.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5vw;
    margin-top: 30px;
}

.buttonsContainer > [class ~= "p-button"]{
    padding: 20px;
}