.errorContainer{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 4px;
    height: 25.6px;
}

.errorTrue{
    color: red;
}

.errorFalse{
    visibility: hidden;
}