.header{
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}

.questionContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.answersPoints{
    margin: 1rem 0;
}

.question{
    min-width: 250px;
    width: 30vw;
    resize: none;
}

.error{
    font-size: 14px;
    text-align: center;
    color: red;
    margin: 0;
    height: 20px;
}

.dropdown{
    width: 135px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.points{
    padding: 0.4rem 1rem;
    width: 100px;
    font-size: 18px;
}

.answersContainer{
    max-height: 250px;
    overflow-y: scroll;
}

.buttonContainer{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
}

.buttonHide{
    visibility: hidden;
}