.buttonZone {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  text-align: center;
}

.btnSignIn {
  all:unset;
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  transition: all 0.3s;

}

.btnSignIn i {
  padding-right: 0.5rem;
}

.btnSignIn:hover {
  cursor: pointer;
  color: white;
  filter: drop-shadow(10px 10px 4px #00000067);
  background-color: #472157;
}

.logo {
  width: 36rem;
  height: auto;
  margin-bottom: 5rem;
  border-radius: 0.5em;
}

.contentPage {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  background-image: url("../../assets/images/fundal-ttjapp\ final.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 420px) {
  .logo {
    width: 280px;
    height: auto;
  }

  .buttonZone {
    width: 300px;
  }
}