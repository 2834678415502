.answerContainer{
    display: flex;
    align-items: center;
    gap: 2vw;
    padding: 0.3rem 9vw;
}

.answerContent{
    min-width: 200px;
    width: 30vw;
    resize: none;
}

.error{
    margin: 0;
    font-size: 14px;
    color: red;
    height: 14px;
    text-align: center;
}