.content {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/fundal-ttjapp\ final.png");
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10rem;
    align-items: center;
}

.table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
    opacity: 0.9;
    color: black;
    height: 40vh;
    width: 20vw;
}