.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 25px;
  opacity: 0.9;
  color: black;
  height: 250px;
}
.row {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;
}

.label {
  padding-right: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.button {
  padding: 1rem 2rem;
}

.align {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media only screen and (max-width: 400px) {
  .content {
    width: 90%;
    gap: 0;
  }

  .row {
    gap: 0;
  }
}
