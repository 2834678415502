.testOpenAnswerContainer{
    display: flex;
    justify-content: center;
}

.questionText{
    font-size: 1.2rem;
    font-weight: bolder;
    user-select: none;
    max-width: 55vw;
    text-align: center;
}

.textareaContainer > [class~="p-inputtextarea"]{
    min-width: 55vw;
    min-height: 55vh;
    resize: none;
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mainButton{
    padding: 20px 40px;
    margin-top: 4.5vh;
}

@media only screen and (max-width: 800px) {
    .mainButton{
        padding: 20px 30px;
    }

    .questionText{
        max-width: 80vw;
    }

    .textareaContainer > [class~="p-inputtextarea"]{
        min-width: 80vw;
    }
}