.container{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, #260d42, #85285f);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 20rem;
    height: auto;
    margin-bottom: 2rem;
    border-radius: 0.5em;
}

.inputContainer{
    padding: 0.5rem 12.5%;
    position: relative;
}

.newPass{
    font-size: 1.2rem;
    display: block;
    margin-bottom: 0.4rem;
}

.confirmPass{
    font-size: 1.2rem;
    display: block;
    margin-bottom: 0.4rem;
}

.input{
    padding: 0.5rem;
    width: 100%;
    outline: black solid 1px;
}

.input:focus{
    outline: black solid 2px;
}

.icons{
    position: absolute;
    color: black;
    right: 55px;
    bottom: 1.1rem;
}

.hideIcon{
    visibility: hidden;
}

.passwordError{
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    max-width: 320px;
    min-height: 45px;
    min-width: 280px;
    text-align: center;
    margin: 0.3rem 0;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

  @media screen and (max-width: 420px) {
    .logo {
      width: 280px;
      height: auto;
    }

    .passwordError{
        max-width: 280px;
    }
}