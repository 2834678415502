.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;
}

.card{
    background-image: linear-gradient(to bottom, #280F43, #63386B);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    width: 80vw;
    height: 65vh;
    padding: 1.5rem 1rem;
    border: solid 2px lightgray;
    border-radius: 12px;
}

.dropdownContainer{
    display: flex;
    justify-content: center;
    gap: 3vw;
}

.dropdown{
    width: 235px;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
}

.button{
    min-width: 150px;
}