/* helper classse */
.flex_center {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

/* page */
.interviews {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 6rem;
}

.interview_select {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.Title {
  width: 30%;
  height: auto;
}

/* drop-down container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* drop-down items */
.options_list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;

  margin-top: 0.5rem;
}

/* buttons */
/* button - optiunea, main_button - alege zi/interval, cta - programeaza, button_active - optiune selectata */
.button,
.main_button {
  border: none;
  width: 30vw;
  min-width: 14rem;
  max-width: 20rem;
  padding: 0.6rem 2rem;
  border-radius: 8px;
}

.button:hover,
.main_button:hover {
  cursor: pointer;
}

.main_button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  color: #fff;
  background-color: #260d42;

  font-weight: 600;
}

.main-button:hover {
  background-position: right;
}

.button {
  background-color: #e1d2dd;
}

.button_active {
  color: #fff;
  background-image: linear-gradient(75deg, #f6d48b, #6d3180, #6d3180, #f6d48b);
  background-size: 300%;
  background-position: left;
  transition: 300ms background-position ease-in-out;
}
.button_disabled {
  background-color: grey;
  pointer-events: none;
}
.button_icon {
  position: absolute;
  right: 1rem;
}

@media only screen and (max-width: 768px) {
  .interview_select {
    gap: 2.5rem;
  }
  .Title {
    width: 85%;
  }
}
