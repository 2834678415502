.navbarContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 4vw;
    background-image: linear-gradient(to right, #cdb7d1, #9c87b2);
    position: sticky;
    top: 0;
    z-index: 10;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resume{
    position: relative;
}

.downloadArea{
    position: absolute;
    left: -175%;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bfa4fa;
    width: 250px;
    height: 100px;
}

.hideDownloadArea{
    display: none;
}

.content{
    margin-left: 2rem;
}

.logout{
    margin-left: 0.8rem;
    border-left: solid 2px white;
    padding-left: 0.8rem;
}

.dropdownIcon{
    margin-top: 12px;
}

.onHover{
    cursor: pointer;
}

