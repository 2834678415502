.header{
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}

.info{
    padding: 0.3rem 2.5rem;
}

.imageContainer{
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #280F43, #63386B);
    padding: 5rem 2.5rem;
    margin: 7vh 2.5rem;
}

.updatedImageContainer{
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #280F43, #63386B);
    padding: 5rem 2.5rem;
    margin: 4vh 2.5rem;
}

.image{
    width: 30vw;
}

.buttonContainer{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
}