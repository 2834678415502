.testContainer{
    position: fixed;
    z-index: 30;
    background-color: white;
    width: 50vw;
    height: 80vh;
    min-width: 300px;
    min-height: 500px;
    left: 25vw;
    top: 15vh;
    padding: 1.5rem 1rem;
}

.innerContainer{
    position: relative;
    height: 72vh;
}

.closeIcon{
    position: absolute;
    right: -5vw;
    z-index: 30;
    top: -5vh;
    cursor: pointer;
}

.confirmationButtonsContainer{
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 0.5rem;
}

.previousIcon{
    position: absolute;
    left: -5vw;
    z-index: 30;
    top: 31vh;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 4px;
}

.nextIcon{
    position: absolute;
    right: -5vw;
    z-index: 30;
    top: 31vh;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 4px;
}