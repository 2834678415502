.container{
  min-height: 78vh;
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10vw;
  padding-top: 5vh;
  padding-bottom: 15vh;
  gap: 5vw;
}
