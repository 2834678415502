.card{
    width: 15vw;
    min-width: 250px;
    cursor: pointer;
    position: relative;
    padding: 0;
}

.partnerLogo{
    min-width: 100%;
    height: auto;
}

.testCompleted{
    font-size: 10px;
    color: red;
    font-weight: bold;
    margin: 0;
}

.modal{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    z-index: 10;
    top: 0;
    left: 0;
    cursor: auto;
}