.mainContainer {
    background-image: linear-gradient(to bottom, #280f43, #63386b);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 4vh 6vw;
    height: 100vh;
  }

  a{
    color: #fbc02d;
  }