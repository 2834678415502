.default {
  width: 14vh;
  height: 6vh;
  background-color: #1a9a32;
  color: white;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 2vh;
}

.btnCv {
  width: 20vh;
  height: 9vh;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 2vh;
  color: white;
  margin-left: 3rem;
}

.btnChangePassword {
  width: 20vh;
  height: 9vh;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 2vh;
  color: white;
}

.btnLogin,
.btnSignIn {
  width: 120px;
  height: 50px;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 3vh;
  color: white;
  margin-left: 2rem;
}

.btnForgotPass{
  width: 100px;
  height: 30px;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1vh;
  color: white;
}

.btnInterviuri {
  height: 7rem;
  width: 10rem;
  background-color: rgb(100, 54, 100);
  border: 2px solid purple;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 3vh;
  color: white;
}

.btnSignIn {
  margin-left: 0;
}

@media screen and (max-width: 420px) {
  .btnLogin,
  .btnSignIn {
    width: 100px;
  }
}
