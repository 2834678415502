.contentPage {
  height: calc(100vh - 10.5rem);
  color: white;
}

.redirecting{
  width: 100vw;
  height: 100vh;
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}