.answerContainer{
    display: flex;
    align-items: center;
    gap: 2.5vw;
    cursor: pointer;
    padding: 1.5vh 0;
    background-color: white;
    color: black;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 1.5vh;
    padding-right: 20px;
    max-width: 55vw;
}

.answerContainer > [class ~= "p-checkbox"]{
    width: 30px;
    height: 30px;
    margin-left: 20px;
}

.answerContainer > [class ~= "p-checkbox"] [class ~= "p-checkbox-box"]{
  width: 30px;
  height: 30px;
  border-width: 3px;
}

.answerText{
    user-select: none;
}

@media only screen and (max-width: 800px) {
    .answerContainer {
      max-width: 80vw;
    }
}